import React, { useCallback } from 'react';
import { alpha } from '@theme-ui/color';
import { Button } from 'components';

type Props = {
  title: string;
  index: number;
  isActive: boolean;
  setSelectedTab: (index: number) => void;
};

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  isActive,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <Button
      sx={{
        background: 'transparent',
        color: '#9599A6',
        outline: 0,
        fontWeight: '500',
        fontSize: '14px',
        py: '11px',
        mr: '8px',
        backgroundColor: 'secondaryBackground',
        borderRadius: '30px',
        '&:hover': {
          cursor: 'pointer',
        },
        '&.selected': {
          color: 'primary',
          backgroundColor: 'quinaryBackground',
          fontWeight: '700',
          border: 'solid',
          borderWidth: '1px',
          borderColor: 'primary',
        },
      }}
      className={isActive ? 'selected' : ''}
      type="button"
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export default TabTitle;
