import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import desktopBannerSrcEs from './images/banner-desktop-es.png';
import mobileBannerSrcEs from './images/banner-mobile-es.png';
import desktopBannerSrcPt from './images/banner-desktop-pt.png';
import mobileBannerSrcPt from './images/banner-mobile-pt.png';
import { useIntl } from 'context/IntlContext';

const ImageDesktop = styled.img`
  border-radius: 16px;
  height: auto;
  object-fit: cover;
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }
`;

const ImageMobile = styled.img`
  border-radius: 16px;
  height: auto;
  object-fit: cover;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`;

interface AmazonBannerProps {
  href: string;
}

/**
 * When removing this component from production, some components need their
 * styling adjusted,due to the fact that this banner is only displayed on
 * the Spanish version of the page. Also try search for the variable
 * 'isSpanishPage' and see if you can find anything related to this banner.
 */
export const AmazonBanner: React.FC<AmazonBannerProps> = ({ href }) => {
  const { locale } = useIntl();

  const isSpanishPage = locale === 'es';
  const isPortugalPage = locale === 'pt';
  return (
    <>
      {isSpanishPage && (
        <>
          <Link to={href}>
            <ImageDesktop src={desktopBannerSrcEs} alt="Amazon Promo Banner" />
            <ImageMobile src={mobileBannerSrcEs} alt="Amazon Promo Banner" />
          </Link>
        </>
      )}
      {isPortugalPage && (
        <>
          <Link to={href}>
            <ImageDesktop src={desktopBannerSrcPt} alt="Amazon Promo Banner" />
            <ImageMobile src={mobileBannerSrcPt} alt="Amazon Promo Banner" />
          </Link>
        </>
      )}
    </>
  );
};
