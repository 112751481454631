import React from 'react';
import { Button } from 'components';
import ArrowRight from '../../images/icons/arrowRight.svg';
import ArrowLeft from '../../images/icons/arrowLeft.svg';

interface ScrollButtonProps {
  onClick: () => void;
  mr?: string;
  ml?: string;
  hide?: boolean;
  direction: 'right' | 'left';
}

const ScrollButton = ({
  onClick,
  mr = '0',
  ml = '0',
  hide = false,
  direction,
}: ScrollButtonProps) => {
  return (
    <Button
      sx={{
        height: '41px',
        minWidth: '41px',
        backgroundColor: hide ? 'transparent' : 'primary',
        borderRadius: '40px',
        padding: 0,
        display: ['none', 'none', 'flex', 'flex'],
        justifyContent: 'center',
        alignItems: 'center',
        mr,
        ml,
        ':disabled': {
          ':hover': {
            cursor: 'default',
          },
        },
      }}
      disabled={hide}
      onClick={onClick}
    >
      {!hide && (direction === 'right' ? <ArrowRight /> : <ArrowLeft />)}
    </Button>
  );
};

export default ScrollButton;
