import React from 'react';

type Props = {
  hash: string;
  title: string;
  id: string;
};

const Tab: React.FC<Props> = ({ id, children }) => {
  return <div id={id}>{children}</div>;
};

export default Tab;
