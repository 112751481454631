import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { Flex, Box, Select, Container } from 'components';

import TabTitle from './TabTitle';
import ScrollButton from './ScrollButton';

type Props = {
  children: ReactElement[];
  activeTab?: string | number;
};

const Tabs: React.FC<Props> = ({ children, activeTab }) => {
  const [selectedTab, setSelectedTab] = useState(activeTab || 0);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [isScrollableLeft, setIsScrollableLeft] = useState<boolean>(false);
  const [isScrollableRight, setIsScrollableRight] = useState<boolean>(true);
  const tabsContainerRef = useRef<Element>();
  const scrollConainerRef = useRef<Element>();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const { scrollWidth } = tabsContainerRef.current!;
    const { clientWidth } = scrollConainerRef.current!;
    if (scrollWidth > clientWidth) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  }, [isMounted, children]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTab(e.target.value);
  };

  return (
    <Box>
      <Box>
        <Container
          sx={{
            display: ['contents', 'contents', 'block', 'block'],
          }}
        >
          <Box>
            <Flex
              ref={scrollConainerRef}
              sx={{
                justifyContent: isScrollable ? 'space-between' : 'center',
              }}
            >
              <ScrollButton
                hide={!isScrollable || !isScrollableLeft}
                mr="8px"
                onClick={() => {
                  const { scrollLeft } = tabsContainerRef.current!;
                  if (scrollLeft - 200 <= 0) setIsScrollableLeft(false);
                  tabsContainerRef.current!.scrollTo({
                    behavior: 'smooth',
                    left: tabsContainerRef.current!.scrollLeft - 200,
                  });
                  setIsScrollableRight(true);
                }}
                direction="left"
              />
              <Flex
                ref={tabsContainerRef}
                sx={{
                  justifyContent: 'normal',
                  '& > div': {
                    width: '100%',
                  },
                  overflowX: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  '& > button:last-of-type': {
                    mr: ['16px', '16px', '0', '0'],
                  },
                  '& > button:first-of-type': {
                    ml: ['16px', '16px', '0', '0'],
                  },
                }}
              >
                {children.map((item, index) => (
                  <TabTitle
                    key={item.props.title}
                    title={item.props.title}
                    isActive={
                      selectedTab === item.props.hash || selectedTab === index
                    }
                    index={index}
                    setSelectedTab={setSelectedTab}
                  />
                ))}
              </Flex>
              <ScrollButton
                direction="right"
                ml="8px"
                hide={!isScrollable || !isScrollableRight}
                onClick={() => {
                  const { scrollLeft, scrollWidth, clientWidth } =
                    tabsContainerRef.current!;

                  if (scrollLeft + 200 >= scrollWidth - clientWidth) {
                    setIsScrollableRight(false);
                  }
                  tabsContainerRef.current!.scrollTo({
                    behavior: 'smooth',
                    left: tabsContainerRef.current!.scrollLeft + 200,
                  });
                  setIsScrollableLeft(true);
                }}
              />
            </Flex>
          </Box>
        </Container>
      </Box>
      <Container>
        {children[selectedTab] ||
          children.find(({ props }) => props.hash === selectedTab)}
      </Container>
    </Box>
  );
};

export default Tabs;
